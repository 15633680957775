import { ConditionalWrapper } from '@/hoc';
import { Gift, Info, Ticket } from '@/icons';
import { NextLink, Number } from '@/atoms';
import CartButton from '@/atoms/cart-button';
import { CartIcon } from '@/molecules';

import { useAddToCart, useBundle, useBundleDispatch } from '@/lib/hooks';
import { isExternal, isNumber, isVariable } from '@/lib/utils';

import type { FunctionComponent, MouseEvent } from 'react';
import type { TypeCardProductProps } from '../types';

type TypeChildButtonProps = Pick<
  TypeCardProductProps,
  'productID' | 'hit' | 'sendEvent' | 'href' | 'type'
> & {
  disabled: boolean;
  onClick?: () => void;
  stopPropagation?: boolean;
  isConfigurableBundle?: boolean;
  quantity?: number;
  isConnectif?: boolean;
};

/**
 * Button
 */
export const Button: FunctionComponent<TypeChildButtonProps> = ({
  productID,
  sendEvent,
  hit,
  type,
  href,
  disabled,
  onClick,
  stopPropagation,
  isConfigurableBundle = false,
  quantity = 1,
  isConnectif = false,
}: TypeChildButtonProps) => {
  const dispatch = useBundleDispatch();
  const bundle = useBundle();

  const Icon = isVariable(type)
    ? Info
    : isExternal(type)
      ? Ticket
      : isConfigurableBundle
        ? Gift
        : CartIcon;

  const isA = isExternal(type) || isVariable(type) || isConfigurableBundle;

  const { state, add } = useAddToCart({ hit, sendEvent, onAdd: onClick });

  const addProduct = async (e: MouseEvent<HTMLElement>) => {
    if (stopPropagation) e.stopPropagation();
    if (productID) add(productID, quantity);
  };

  return !isConnectif && dispatch && bundle && productID ? (
    <Number
      onAdd={() => dispatch.add(productID)}
      onSubtract={() => dispatch.subtract(productID)}
      value={bundle.products.get(productID)?.quantity ?? 0}
      addDisabled={dispatch.helpers.addDisabled(productID)}
      subtractDisabled={dispatch.helpers.subtractDisabled(productID)}
      disabled={disabled || dispatch.helpers.disabled(productID)}
      className={
        bundle.variants.length === 0 && !bundle.autoCalculatePrice
          ? 'w-full'
          : ''
      }
    />
  ) : (
    <ConditionalWrapper
      condition={isA}
      wrapper={(children) => (
        <NextLink href={href} passHref>
          {children}
        </NextLink>
      )}
    >
      <CartButton
        Icon={Icon}
        disabled={disabled || !isNumber(productID)}
        variant={isConfigurableBundle ? 'button' : 'default'}
        {...(isA ? { as: 'a' } : { as: 'button', onClick: addProduct, state })}
        {...(isConfigurableBundle && { className: 'w-full' })}
        testid="add-to-cart"
      >
        {isConfigurableBundle ? (
          'Configurar'
        ) : (
          <span className="max-lg:hidden">Añadir</span>
        )}
      </CartButton>
    </ConditionalWrapper>
  );
};

Button.displayName = 'Button';
